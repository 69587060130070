import Big from "big.js";
import { Appointment } from "../../Appointment/AppointmentCardsContainer/types";

const calcRemainingBalance = (appointment: Appointment): number => {
  const totalRefunds = appointment.appointment_refunds.reduce(
    (acc, refund) => acc.plus(refund.amount),
    new Big(0),
  );

  const totalPayments = appointment.appointment_payments.reduce(
    (acc, payment) => acc.plus(payment.amount || 0),
    new Big(0),
  );

  const remainingBalance = appointment
    ? new Big(appointment.total_cost_amount)
        .minus(appointment.discount_amount || 0)
        .minus(totalPayments)
        .plus(totalRefunds)
        .toNumber()
    : 0;

  return remainingBalance;
};

export default calcRemainingBalance;
