import React, { FC } from "react";
import { useFormikContext } from "formik";
import FormikTextField, {
  FormikTextFieldProps,
} from "../../Unknown/FormikTextField";
import Grid from "../../Unknown/Grid";
import useTranslations from "./useTranslations";
import { LabelTranslationsKey } from "./types";
import { inputSpacing } from "./constants";
import FormikSwitch from "../../Unknown/FormikSwitch";
import InputAdornment from "../../Unknown/InputAdornment";
import Typography from "../../Unknown/Typography";

type CenterAddOnFormProps = {
  currencyCode: string;
  labelTranslations: Record<LabelTranslationsKey, string>;
};

const CenterAddOnForm: FC<CenterAddOnFormProps> = ({
  currencyCode,
  labelTranslations,
}) => {
  const translations = useTranslations({ currencyCode });

  const { isSubmitting } = useFormikContext();

  const defaultTextFieldProps: Partial<FormikTextFieldProps> = {
    fullWidth: true,
    required: true,
    autoComplete: "off",
    disabled: isSubmitting,
  };

  return (
    <Grid container spacing={inputSpacing} direction="row">
      <Grid item container spacing={inputSpacing}>
        <Grid item xs={6}>
          <FormikTextField
            {...defaultTextFieldProps}
            name="name"
            label={labelTranslations.labelName}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikTextField
            {...defaultTextFieldProps}
            name="price"
            label={labelTranslations.labelPrice}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>{translations.currencySymbol}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={inputSpacing}>
        <Grid item>
          <FormikSwitch
            label={labelTranslations.labelStat}
            name="isStat"
            disabled={isSubmitting}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CenterAddOnForm;
